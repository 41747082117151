import React, { FC } from 'react';
import { Section } from '../Section/Section';
import { SectionHeader } from '../Section/SectionHeader';
import { SeeAllResultsButton } from '../SectionListResults/SeeAllResultsButton';
import { NewTerritoryButton } from '../NewTerritoryButton/NewTerritoryButton';
import { getTerritoryDiscoveryPageUrl } from '../../services/pagesUrl/getTerritoryDiscoveryPageUrl';
import { TERRITORY_DISCOVERY_SORTING_DROPDOWN_ITEMS, TerritorySortBy } from '../TerritoryDiscovery/constants';
import { SortingDropdown } from '../SortingDropdown/SortingDropdown';
import { useFetchTerritoryDiscoveryData } from '../TerritoryDiscovery/hooks/useFetchTerritoryDiscoveryData';
import { SectionListResults } from '../SectionListResults/SectionListResults';
import { TerritoryDiscoveryResults } from '../TerritoryDiscovery/TerritoryDiscoveryResults';
import { TerritoryDiscoveryNoResults } from '../TerritoryDiscovery/TerritoryDiscoveryNoResults';
import { TerritoryDiscoveryResultsPlaceholder } from '../TerritoryDiscovery/TerritoryDiscoveryResultsPlaceholder';
import { useAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { TTerritoryDiscoverySectionPlacement } from './constants';

type TTerritoryDiscoverySectionProps = {
  onSort?: (sortBy: string) => void;
  sortBy?: TerritorySortBy;
  title?: string;
  tooltipText?: string;
  searchTerm?: string | null;
  take: number;
  initialTake?: number;
  showSeeAllButton?: boolean;
  placement?: TTerritoryDiscoverySectionPlacement;
  showNewTerritoryButton?: boolean;
};

export const TerritoryDiscoverySection: FC<TTerritoryDiscoverySectionProps> = ({
  onSort,
  sortBy,
  title,
  tooltipText,
  searchTerm,
  take,
  initialTake = take,
  showSeeAllButton,
  placement,
  showNewTerritoryButton,
}) => {
  const { isAuthenticated } = useAuthenticatedUser();
  const { results, loading, fetchMore, resultsCount, called } = useFetchTerritoryDiscoveryData({
    searchTerm,
    sortBy,
    initialTake,
    take,
    placement,
  });

  const sectionTitleRightSide = (
    <>
      {showSeeAllButton && <SeeAllResultsButton url={getTerritoryDiscoveryPageUrl()} />}
      {showNewTerritoryButton && <NewTerritoryButton isAuthenticated={isAuthenticated} />}
    </>
  );

  return (
    <Section
      header={
        <SectionHeader
          title={title}
          tooltipText={tooltipText}
          leftSide={
            onSort && sortBy ? (
              <SortingDropdown
                items={TERRITORY_DISCOVERY_SORTING_DROPDOWN_ITEMS}
                onSortByChange={onSort}
                purpose="territory_discovery"
                defaultSortBy={sortBy}
                dropdownLabelClassName="!text-sm !text-t2-neutral-grey08 !bg-t2-neutral-grey03"
              />
            ) : null
          }
          rightSide={sectionTitleRightSide}
        />
      }
    >
      <SectionListResults
        results={<TerritoryDiscoveryResults results={results} />}
        emptyResults={<TerritoryDiscoveryNoResults searchTerm={searchTerm} />}
        resultsPlaceholder={<TerritoryDiscoveryResultsPlaceholder numberOfElements={initialTake} />}
        fetchMore={fetchMore}
        loading={loading}
        data={results}
        resultsCount={resultsCount}
        take={take}
        called={called}
      />
    </Section>
  );
};
