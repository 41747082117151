import React, { ReactElement, useRef } from 'react';
import { FetchMoreFunction } from '@apollo/client/react/hooks/useSuspenseQuery';
import { LoadMoreButton } from '../LoadMoreButton/LoadMoreButton';
import { useSectionListResults } from './hooks/useSectionListResults';

type TSectionListResultsProps<TData> = {
  resultsPlaceholder?: ReactElement;
  emptyResults?: ReactElement;
  results: ReactElement;
  loading: boolean;
  fetchMore?: FetchMoreFunction<any, any>;
  take: number;
  resultsCount: number;
  data: TData;
  called: boolean;
};

export const SectionListResults = <TData extends object[]>({
  resultsPlaceholder,
  emptyResults,
  results,
  loading,
  fetchMore,
  take,
  resultsCount,
  data,
  called,
}: TSectionListResultsProps<TData>) => {
  const resultsRef = useRef<HTMLDivElement>(null);
  const {
    fetchMore: fetchMoreResults,
    hasMoreToLoad,
    noResults,
    firstResultsLoading,
    hasMore,
  } = useSectionListResults({
    loading,
    results: data,
    fetchMore,
    take,
    resultsCount,
    called,
  });

  if (firstResultsLoading && resultsPlaceholder) {
    return resultsPlaceholder;
  }

  if (noResults && emptyResults) {
    return emptyResults;
  }

  return (
    <div ref={resultsRef} className="w-full flex flex-col items-center gap-6">
      {results}
      {hasMore && fetchMore && (
        <LoadMoreButton
          isLoading={loading}
          onClick={fetchMoreResults}
          hasMoreToLoad={hasMoreToLoad}
          sectionTitleRef={resultsRef}
        />
      )}
    </div>
  );
};
